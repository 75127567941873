import React from 'react'

import { Link } from 'gatsby'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import bannerImg from '../../../../images/webinars/red-7.png'
import Layout from '../../../../components/layout'
import Nav from '../../../../components/nav'
import SEO from '../../../../components/seo'
import '../../../../styles/index.scss'

const WebinarsPage = () => {
    return (
        <Layout lang="es">

            <SEO
                title="Webinar Febrero 2023 - Red-7 y Optiwe"
                description="Webinar sobre como mejorar las ventas y la atención al cliente para clientes de Red-7"
                lang="es"
                locales={
                    [
                        { hreflang: 'es', href: 'https://optiwe.com/es/recursos/webinars/febrero-2023-con-red-7/' }
                    ]
                }
            />

            <main className="container-fluid ow-banner">
                {
                    /*
                    ^*********
                    * HEADER *
                    **********
                    */
                }
                <Nav lang="es" showButtons />

                {
                    /*
                    ***************
                    * MAIN BANNER *
                    ***************
                    * TODO: If the banner is used in another view, create a <Banner /> component
                    */
                }
                <div className="container ow-banner__content">
                    <div className="row">
                        <div className="col-xl-6 col-xxl-6 ow-banner__home-content">
                            <h1 className="ow-banner__title">
                                <strong>Webinar |</strong> Optiwe en colaboración con Red-7
                            </h1>
                            <p className="ow-banner__text">
                                Toda la información sobre cómo automatizar la atención al cliente en WhatsApp con Inteligencia Artificial
                            </p>
                            <p className="ow-banner__text">
                                Cuando: Viernes 17 de Febrero 2023 - 12 PM (GMT-8)
                            </p>
                            <p className="ow-banner__text">
                                Para anotarte al webinar, utiliza <Link to="https://us06web.zoom.us/webinar/register/WN_42QNOrB1TuCKOY9AlLN-Hg">el siguiente formulario de Zoom</Link>
                            </p>
                            <p className="ow-banner__text">
                                1. Cómo crear un Chatbot para WhatsApp
                            </p>
                            <p className="ow-banner__text">
                                2. Cómo conectar un equipo de ventas y atención al cliente a un CRM para WhatsApp
                            </p>
                            <p className="ow-banner__text">
                                3. Cómo realizar envíos masivos por WhatsApp para aumentar las ventas
                            </p>
                        </div>
                        <div className="col-md-6 col-xxl-7 ow-banner__home-image">
                            <img src={bannerImg} alt="optiwe" className="ow-banner__img" />
                        </div>
                    </div>
                </div>
            </main>

        </Layout>
    )
}

export default WebinarsPage
